import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { A11yElementClickDirective } from '@client-portal/directives/a11y-element-click';
import { ToastConfig } from '@client-portal/models/common';
import { ToastType } from '@client-portal/enums/common/toast';
import { ToastService } from '@client-portal/services/toast';
import { IconComponent } from '@client-portal/ui/icon';
import { ButtonComponent } from '../button';

@Component({
  selector: 'ui-toast-message',
  standalone: true,
  imports: [A11yElementClickDirective, ButtonComponent, NgClass, IconComponent],
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastMessageComponent {
  @Input() public toastConfig: ToastConfig | undefined;
  public ToastType = ToastType;

  constructor(private _toastService: ToastService) {}

  public hideToast(): void {
    this._toastService.hideToast();
  }

  public refreshPage(): void {
    window.location.reload();
  }
}
