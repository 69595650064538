@if (toastConfig) {
  <div class="toast-container">
    <div class="toast-container__content">
      @if (toastConfig.title) {
        <div class="toast-container__content__header">
          <div class="toast-container__{{ toastConfig.type }}">
            @switch (toastConfig.type) {
              @case (ToastType.Success) {
                <ui-icon
                  icon="check_circle"
                  class="toast-container__{{ toastConfig.type }}-icon"
                />
              }
              @case (ToastType.Error) {
                <ui-icon
                  icon="error"
                  filled
                  class="toast-container__{{ toastConfig.type }}-icon"
                />
              }
            }
            {{ toastConfig.title }}
          </div>

          <ui-icon
            icon="close"
            class="close"
            (uiA11yElementClick)="hideToast()"
          />
        </div>
      }

      @if (toastConfig.message) {
        <p
          [ngClass]="{
            'gtm-error-event': toastConfig.type === ToastType.Error
          }"
          class="toast-container__content__message"
        >
          {{ toastConfig.message }}
        </p>
      }

      @if (
        !toastConfig.hideRefreshButton && toastConfig.type === ToastType.Error
      ) {
        <ui-button (handleClick)="refreshPage()" cssClasses="button-full-width">
          Refresh page
        </ui-button>
      }
    </div>
  </div>
}
